.UIContact {
    width: 100%;
    z-index: -3;
    margin-top: -140px;
}

.UIContact a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
}
.UIContact a:hover {
    color: #fff;
}

.UIContact .container-fluid.background {
    background-color: #373f4d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#fff));
    background-image: linear-gradient(#222731, #fff);
    padding: 250px 0 220px;
}

.UIContact .mail-info {
    position: absolute;
    padding: 10px 30px;
    margin-top: -50px;
    margin-left: 200px;
    border-radius: 10px;
}


.UIContact .heading {
    text-align: inherit;
    width: 100%;
}

.UIContact .heading h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #5eba47;
}

.UIContact .heading h1 {
    font-size: 85px;
    font-weight: 700;
    color: #fff;
}

.UIContact .text {
    color: white;

    .followus {
        text-transform: uppercase;
    }
}

.UIContact .container.hero-image {
    margin-top: -250px;

    img {
        width: inherit;
    }
}

.UIContact .container.content {
    margin: 50px auto 100px;
    padding: 0 10vw;
}

.UIContact .container.video {
    margin: 0 auto 5vw;
    text-align: center;

    video#video {
        border-radius: 20px;
        width: 100%;
    }
}

.UIContact form .row {
    margin: 0 0 20px;

    .form-element {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: stretch;
    }
}

.UIContact form .row .form-element label {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0 0 15px 0;
}

.UIContact form .row .form-element input,
.UIContact form .row .form-element textarea {
    background-color: transparent;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #888a90;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #32363f;
}

.UIContact form .row .form-element input:-internal-autofill-selected,
.UIContact form .row .form-element textarea:-internal-autofill-selected {
    background: transparent;
}

.UIContact form .row .form-element input:focus,
.UIContact form .row .form-element textarea:focus {
    border: 1px solid #FFF;
    background-color: white;
    color: #222731;
}

.UIContact input:focus-visible,
.UIContact textarea:focus-visible {
    outline: none;
}

.UIContact .container.image img {
        width: 100%;
        margin: -50px 0 100px;
}

.UIContact input:-internal-autofill-selected {
    background-color: transparent!important;
}

@media (max-width: 575.98px) { 
    .UIContact .heading h1 {
        font-size: 50px;
    }
    .UIContact .heading h2 {
        font-size: 20px;
    }

    .UIContact .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UIContact form {
        margin-top: 80px;
    }
    .UIContact form .row .form-element input, .UIContact form .row .form-element textarea {
        width: 100%;
    }
    .UIContact .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UIContact .container.video {
        margin: -180px auto 25vw;
    }
    .UIContact .container.image img {
        margin: -110px 0 220px;
        width: 100%;
    }
}
@media (min-width : 576px) and (max-width: 767.98px) {
    .UIContact .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UIContact form {
        margin-top: 80px;
    }
    .UIContact form .row .form-element input, .UIContact form .row .form-element textarea {
        width: 100%;
    }
    .UIContact .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UIContact .container.video {
        margin: -180px auto 25vw;
    }

}
@media (min-width : 768px) and (max-width: 991.98px) { 
    .UIContact .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UIContact form {
        margin-top: 80px;
    }
    .UIContact form .row .form-element input, .UIContact form .row .form-element textarea {
        width: 100%;
    }
    .UIContact .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UIContact .container.video {
        margin: -180px auto 25vw;
    }
} 
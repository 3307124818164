.UIHouseholds {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UIHouseholds .container-fluid.background {
  padding: 400px 0 400px;
  position: relative;
}

.UIHouseholds .container-fluid.background .bg-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.UIHouseholds video.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}

.UIHouseholds .container.hero-image {
  margin-top: -400px;
  z-index: 9;
  position: relative;
}
.UIHouseholds .container.hero-image img {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -21%;
}

.UIHouseholds .container.content {
  margin: 50px auto 100px;
  padding: 0 10vw;
}

.UIHouseholds .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UIHouseholds .container.video video#video {
  border-radius: 20px;
}

.UIHouseholds .overlay {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: -1;
  margin: -1260px 0 -530px;
}

.UIHouseholds .container.content .text img {
  margin-left: 50%;
  transform: translatex(-50%);
}

@media (max-width: 575.98px) {
  .UIHouseholds .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIHouseholds .container-fluid.background {
    padding: 40vw 0 70vw;
    position: relative;
  }
  .UIHouseholds .overlay {
    margin-top: -1240px;
    height: 1720px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -260px;
  }
  .UIHouseholds .smaller-title {
    font-size: 26px !important;
  }
  .UIHouseholds .smallish-title {
    font-size: 40px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIHouseholds .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIHouseholds .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIHouseholds .overlay {
    margin-top: -1220px;
    height: 1740px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -170px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIHouseholds .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIHouseholds .container-fluid.background {
    padding: 35vw 0 32vw;
    position: relative;
  }
  .UIHouseholds .overlay {
    margin-top: -1240px;
    height: 1780px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -180px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .UIHouseholds .container-fluid.background .bg-image {
    left: -40%;
    right: -40%;
  }
  .UIHouseholds .container-fluid.background {
    position: relative;
    padding: 30vw 0 20vw;
  }
  .UIHouseholds .overlay {
    margin-top: -1250px;
    height: 1730px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -60px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .UIHouseholds .container-fluid.background .bg-image {
    left: -30%;
    right: -30%;
  }
  .UIHouseholds .container-fluid.background {
    padding: 23vw 0 18vw;
    position: relative;
  }
  .UIHouseholds .overlay {
    margin-top: -1270px;
    height: 1780px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -150px;
  }
}
@media (min-width: 1400px) and (max-width: 2000px) {
  .UIHouseholds .container-fluid.background {
    padding: 250px 0 220px;
    position: relative;
  }
  .UIHouseholds .overlay {
    margin-top: -1280px;
    height: 1780px;
  }
  .UIHouseholds .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIHouseholds .container.hero-image {
    margin-top: -250px;
  }
}/*# sourceMappingURL=Households.css.map */
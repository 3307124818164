.UILegalNotice {
  width: 100%;
  z-index: -3;
  margin-top: -140px;
}

.UILegalNotice .container-fluid.background {
  background-color: #373f4d;
  background-image: linear-gradient(#222731, #fff);
  padding: 250px 0 220px;
}

.UILegalNotice .content .text {
  margin: 0 -5vw;
  text-align: justify;
}
.UILegalNotice .content .text h4 {
  font-size: 36px;
  text-align: left;
  color: #373f4d;
  margin: 50px 0 20px;
}
.UILegalNotice .content .text p {
  text-align: justify;
}

.UILegalNotice .heading {
  text-align: inherit;
}

.UILegalNotice .heading h2 {
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  color: #5eba47;
}

.UILegalNotice a {
  color: #5eba47;
  text-decoration: none;
}

.UILegalNotice .heading h1 {
  font-size: 75px;
  font-weight: 700;
  color: #fff;
}

.UILegalNotice li {
  margin-bottom: 5px;
}

.UILegalNotice .container.hero-image {
  margin-top: -250px;
}
.UILegalNotice .container.hero-image img {
  width: inherit;
}

.UILegalNotice .container.content {
  margin: 50px auto 100px;
  padding: 0 10vw;
}

.UILegalNotice .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UILegalNotice .container.video video#video {
  border-radius: 20px;
  width: 100%;
}

.UILegalNotice form .row {
  margin: 0 0 20px;
}
.UILegalNotice form .row .form-element {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
}

.UILegalNotice form .row .form-element label {
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  padding: 0 0 15px 0;
}

.UILegalNotice form .row .form-element input,
.UILegalNotice form .row .form-element textarea {
  background-color: transparent;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #888a90;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #32363f;
}

.UILegalNotice form .row .form-element input:-internal-autofill-selected,
.UILegalNotice form .row .form-element textarea:-internal-autofill-selected {
  background: transparent;
}

.UILegalNotice form .row .form-element input:focus,
.UILegalNotice form .row .form-element textarea:focus {
  border: 1px solid #FFF;
  background-color: white;
  color: #222731;
}

.UILegalNotice input:focus-visible,
.UILegalNotice textarea:focus-visible {
  outline: none;
}

.UILegalNotice .text ul li a {
  text-decoration: none;
  color: #5eba47;
}

.UILegalNotice .text ul li a:hover {
  color: green;
}

.UILegalNotice .text ul li span {
  color: #888a90;
}

.UILegalNotice .text.documents p {
  margin: 20px 0 5px;
  font-weight: 600;
}

.UILegalNotice .text.documents p::after {
  display: contents;
  content: ":";
}

.UILegalNotice .heading {
  width: 100%;
}

@media (max-width: 575.98px) {
  .UILegalNotice .heading h1 {
    font-size: 50px;
  }
  .UILegalNotice .heading h2 {
    font-size: 20px;
  }
  .UILegalNotice .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UILegalNotice form {
    margin-top: 80px;
  }
  .UILegalNotice form .row .form-element input, .UILegalNotice form .row .form-element textarea {
    width: 100%;
  }
  .UILegalNotice .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UILegalNotice .container.video {
    margin: -180px auto 25vw;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UILegalNotice .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UILegalNotice form {
    margin-top: 80px;
  }
  .UILegalNotice form .row .form-element input, .UILegalNotice form .row .form-element textarea {
    width: 100%;
  }
  .UILegalNotice .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UILegalNotice .container.video {
    margin: -180px auto 25vw;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UILegalNotice .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UILegalNotice form {
    margin-top: 80px;
  }
  .UILegalNotice form .row .form-element input, .UILegalNotice form .row .form-element textarea {
    width: 100%;
  }
  .UILegalNotice .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UILegalNotice .container.video {
    margin: -180px auto 25vw;
  }
}/*# sourceMappingURL=LegalNotice.css.map */

.UIJoinUs {
    width: 100%;
    z-index: -1;
    margin-top: -140px;
    white-space: pre-line;  
vertical-align: bottom;
}

.UIJoinUs .container-fluid.background {
    background-color: #373f4d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#fff));
    background-image: linear-gradient(#222731, #fff);
    padding: 260px 0 350px;
}

.UIJoinUs .container-fluid.background .bg-image {
    background-image: url('/public/images/whoweare-header-bg.png');
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.UIJoinUs .heading {
    text-align: center;
}

.UIJoinUs .heading h1 {
    font-size: 85px;
    font-weight: 700;
    color: #fff;
}

.UIJoinUs .heading h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #5eba47;
}

.UIJoinUs .heading h3 {
    font-size: 72px;
    font-weight: 700;
    color: #373f4d;
}

.UIJoinUs .content .text {
    width: 70%;
    margin: 0 auto;
    text-align: center;

    h4 {
        color: #373f4d;
        font-weight: 700;
        text-align: center;
    }
}

.UIJoinUs .container.content {
    margin: 100px 0 0 0;
    .heading {
        margin: 100px 0 50px;
    }
}

.UIJoinUs .container .card {
    border: none;
    background-color: transparent;
    margin: 45px 45px 20px 45px; 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;   

   
}

.UIJoinUs .card-content {
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 80%;
    color: #fff;

    h4 {
        font-size: 22px;
    }
    h2 {        
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        color: #5eba47;
        } 
    
}
.UIJoinUs .container.cards-container {
    margin-top: -200px;
}

.UIJoinUs .cards .card-shadow {
    margin-top: 0px;
}

.UIJoinUs .row.cards .readmore {
    margin-right: 20px;
}


@media (max-width: 575.98px) { 
    .UIJoinUs .heading h1,
    .UIJoinUs .heading h3 {
        font-size: 50px;
    }
     .UIJoinUs .heading h2 {
        font-size: 20px;
    }
    .UIJoinUs .card-content h2 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .UIJoinUs .card-content h4 {
        font-size: 15px;
        margin-bottom: 15px;
    }
    .UIJoinUs .row.cards .readmore {
        visibility: hidden;
    }
    .UIJoinUs .card-content p {
        display: none;
    }
    .UIJoinUs .container-fluid.background {
        padding: 180px 0 280px;
    }
    .UIJoinUs .card-content {
        bottom: 15px;
        left: 18px;
        width: 90%;
    }
    .UIJoinUs .content .text {
        width: 90%;
    }
    .UIJoinUs .container.cards-container {
        margin-top: -200px;
    }
    .UIJoinUs .row.cards .readmore {
        visibility: hidden;
}
}

@media (min-width : 576px) and (max-width: 767.98px) { 
    .UIJoinUs .heading h1,
    .UIJoinUs .heading h3 {
        font-size: 60px;
    }
    .UIJoinUs .heading h2 {
        font-size: 22px;
    }
    .UIJoinUs .card-content h2 {
        font-size: 15px;
    }
    .UIJoinUs .card-content h4 {
        font-size: 17px;
    }
    .UIJoinUs .row.cards .readmore {
        margin-right: 10px;
    }
    .UIJoinUs .container-fluid.background {
        padding: 180px 0 300px;
    }
    .UIJoinUs .card-content {
        bottom: 50px;
        left: 30px;
        width: 90%;
    }
    .UIJoinUs .content .text {
        width: 90%;
    }
    .UIJoinUs .container.cards-container {
        margin-top: -200px;
    }
}
@media (min-width : 768px) and (max-width: 991.98px) { 
    .UIJoinUs .heading h1,
    .UIJoinUs .heading h3 {
        font-size: 60px;
    }
    .UIJoinUs .heading h2 {
        font-size: 22px;
    }
    .UIJoinUs .card-content h2 {
        font-size: 22px;
    }
    .UIJoinUs .card-content h4 {
        font-size: 25px;
    }
    .UIJoinUs .container-fluid.background {
        padding: 180px 0 300px;
    } 
    .UIJoinUs .card-content {
        bottom: 60px;
        left: 50px;
        width: 90%;
    }
    .UIJoinUs .content .text {
        width: 90%;
    }
    .UIJoinUs .container.cards-container {
        margin-top: -200px;
    }
}

@media (min-width : 992px) and (max-width: 1199.98px) { 
    .UIJoinUs .heading h1,
    .UIJoinUs .heading h3 {
        font-size: 65px;
    }
    .UIJoinUs .heading h2 {
        font-size:20px;
    }
    .UIJoinUs .card-content h4 {
        font-size: 18px;
    }
    .UIJoinUs .row.cards .readmore {
        margin-right: 10px;
    }
    .UIJoinUs .card-content h2 {
        font-size: 16px;
    }
    .UIJoinUs .container-fluid.background {
        padding: 180px 0 300px;
    }   
    .UIJoinUs .card-content {
        top: 50px;
        bottom: 10px;
        left: 20px;
        width: 80%;
    }
    .UIJoinUs .content .text {
        width: 80%;
    }
    .UIJoinUs .container.cards-container {
    margin-top: -200px;
}
}
@media (min-width : 1200px) and (max-width: 1399.98px) { 
    .UIJoinUs .heading h1,
    .UIJoinUs .heading h3 {
        font-size: 70px;
    }
    .UIJoinUs .heading h2 {
        font-size: 22px;
    }
    .UIJoinUs .card-content h2 {
        font-size: 18px;
    }
    .UIJoinUs .card-content h4 {
        font-size: 20px;
    }
    .UIJoinUs .row.cards .readmore {
        margin-right: 18px;
    }

    .UIJoinUs .container-fluid.background {
        padding: 180px 0 300px;
    }   
    .UIJoinUs .card-content {
        bottom: 50px;
        left: 30px;
        width: 80%;
    }
    .UIJoinUs .content .text {
        width: 80%;
    }
    .UIJoinUs .container.cards-container {
        margin-top: -200px;
    }
}
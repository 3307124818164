.UIElsupply {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UIElsupply .container-fluid.background {
  padding: 400px 0 400px;
  position: relative;
}

.UIElsupply .container-fluid.background .bg-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.UIElsupply video.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}

.UIElsupply .container.hero-image {
  margin-top: -400px;
  z-index: 9;
  position: relative;
}
.UIElsupply .container.hero-image img {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -21%;
}

.UIElsupply .container.content {
  margin: 50px auto 100px;
  padding: 0 10vw;
}

.UIElsupply .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UIElsupply .container.video video#video {
  border-radius: 20px;
}

.UIElsupply .overlay {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: -1;
  margin: -1260px 0 -530px;
}

.UIElsupply .container.content .text img {
  margin-left: 50%;
  transform: translatex(-50%);
}

.UIElsupply span.green {
  color: #5dbb47;
  font-weight: 700;
}
.UIElsupply .greycards {
  flex-wrap: wrap;
}
.UIElsupply .greycards .greycard {
  margin: 1%;
  background-color: #e9e9ea;
  border-radius: 8px;
  padding: 50px;
  position: relative;
  flex: 1 0 48%;
}

.UIElsupply .hero-cards-holder {
  margin-top: -70dvh;
  z-index: 9;
  position: relative;
}
.UIElsupply .hero-cards-holder .hero-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.UIElsupply .hero-cards-holder .hero-cards .hero-card {
  background-color: #232832;
  border-radius: 25px;
  padding: 30px;
  flex: 0 0 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.UIElsupply .hero-cards-holder .hero-cards .hero-card .title {
  color: white;
}
.UIElsupply .hero-cards-holder .hero-cards .hero-card .text {
  color: #a5a5a5;
}
.UIElsupply .hero-cards-holder .hero-cards .hero-card .readmore {
  text-align: right;
}
.UIElsupply .hero-cards-holder .hero-cards .hero-card .readmore a {
  font-size: 14px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 3px solid #5eba47;
  padding-bottom: 3px;
}

.UIElsupply .medium_cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.UIElsupply .medium_cards .medium_card {
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  background-color: #232832;
  border-radius: 12px;
  transition: 0.3s ease;
  cursor: auto;
}
.UIElsupply .medium_cards .medium_card:hover {
  background-color: #5dbb47;
}
.UIElsupply .medium_cards .medium_card h2 {
  position: absolute;
  top: 50px;
  left: 30px;
  color: #fff;
}
.UIElsupply .medium_cards .medium_card .readmore {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.UIElsupply .medium_cards .medium_card .readmore a {
  font-size: 14px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 3px solid #5eba47;
  padding-bottom: 3px;
}
.UIElsupply .medium_cards .medium_card img {
  display: block;
  width: 100%;
  height: auto;
}

.UIElsupply .phone_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.UIElsupply .phone_cards .phone_card {
  flex: 0 0 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UIElsupply .phone_cards .phone_card img {
  margin-bottom: 20px;
}

.UIElsupply .functions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.UIElsupply .functions .function {
  background-color: #232832;
  flex: 0 0 31%;
  position: relative;
  padding: 25px 35px;
  border-radius: 10px;
  margin: 10px auto;
}
.UIElsupply .functions .function .function_number {
  position: absolute;
  background-color: #5dbb46;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  left: -20px;
  top: 50px;
}
.UIElsupply .functions .function .function_number span {
  color: white;
  margin-left: 40%;
  line-height: 2.4;
  position: relative;
}
.UIElsupply .functions .function .function_title {
  text-align: start;
  color: #a5a5a5;
}
.UIElsupply .functions .function .function_title h4 {
  color: white;
}
.UIElsupply .functions .function.function50 {
  flex: 0 0 48%;
  margin: 1%;
}
.UIElsupply .functions .function.function50 strong {
  color: rgb(218, 218, 218);
}

.modalbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  -webkit-backdrop-filter: brightness(0.2);
          backdrop-filter: brightness(0.2);
}
.modalbox button {
  display: block;
  position: fixed;
  right: 20px;
  background-color: transparent;
  border: 0;
  font-size: 40px;
  top: 20px;
  color: white;
}
.modalbox button:hover {
  color: #5eba47;
}
.modalbox .modalcontent {
  position: fixed;
  height: auto;
  width: auto;
  background-color: #e9e9ea;
  height: auto;
  width: auto;
  padding: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.UIElsupply .graybox {
  margin-bottom: 100px;
  margin-top: 100px;
  background-color: #e9e9ea;
  border-radius: 8px;
  padding: 110px 90px;
  position: relative;
}
.UIElsupply .graybox h2 {
  color: #5dbb47;
  margin-bottom: 20px;
}
.UIElsupply .graybox p {
  color: #535353;
}
.UIElsupply .graybox .solarimage {
  position: absolute;
  width: auto;
  height: auto;
  right: -60px;
  bottom: -50px;
}

.UIElsupply .meter_box {
  border-radius: 10px;
  padding: 20px 30px;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.UIElsupply .meter_box .box_row a img {
  margin: 10px 10px 10px 0;
}

.UIElsupply p a {
  text-decoration: none;
  color: #5dbb46;
}

@media (max-width: 575.98px) {
  .UIElsupply .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIElsupply .container-fluid.background {
    padding: 40vw 0 70vw;
    position: relative;
  }
  .UIElsupply .overlay {
    margin-top: -1240px;
    height: 1720px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -260px;
  }
  .UIElsupply .medium_cards {
    justify-content: center;
  }
  .UIElsupply .graybox {
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 80px 60px;
  }
  .UIElsupply .graybox h2 {
    margin-bottom: 20px;
  }
  .UIElsupply .graybox .solarimage {
    right: -10px;
    bottom: -90px;
    width: 75%;
  }
  .UIElsupply .functions .function.function50 {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
  .UIElsupply .functions .function.function50 strong {
    color: rgb(218, 218, 218);
  }
  .UIElsupply .greycards .greycard,
  .UIElsupply .functions .function {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIElsupply .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIElsupply .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIElsupply .overlay {
    margin-top: -1220px;
    height: 1740px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -170px;
  }
  .UIElsupply .medium_cards {
    justify-content: center;
  }
  .UIElsupply .graybox {
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 80px 60px;
  }
  .UIElsupply .graybox h2 {
    margin-bottom: 20px;
  }
  .UIElsupply .graybox .solarimage {
    right: -30px;
    bottom: -50px;
    width: 70%;
  }
  .UIElsupply .functions .function.function50 {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
  .UIElsupply .functions .function.function50 strong {
    color: rgb(218, 218, 218);
  }
  .UIElsupply .greycards .greycard,
  .UIElsupply .functions .function {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIElsupply .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
  .UIElsupply .container-fluid.background {
    padding: 35vw 0 32vw;
    position: relative;
  }
  .UIElsupply .overlay {
    margin-top: -1240px;
    height: 1780px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -180px;
  }
  .UIElsupply .medium_cards {
    justify-content: center;
  }
  .UIElsupply .graybox {
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 80px 60px;
  }
  .UIElsupply .graybox h2 {
    margin-bottom: 20px;
  }
  .UIElsupply .graybox .solarimage {
    right: -60px;
    bottom: -50px;
    width: 65%;
  }
  .UIElsupply .functions .function.function50 {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
  .UIElsupply .functions .function.function50 strong {
    color: rgb(218, 218, 218);
  }
  .UIElsupply .greycards .greycard,
  .UIElsupply .functions .function {
    flex: 0 0 90%;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .UIElsupply .container-fluid.background .bg-image {
    left: -40%;
    right: -40%;
  }
  .UIElsupply .container-fluid.background {
    position: relative;
    padding: 30vw 0 20vw;
  }
  .UIElsupply .overlay {
    margin-top: -1250px;
    height: 1730px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -60px;
  }
  .UIElsupply .medium_cards {
    justify-content: center;
  }
  .UIElsupply .graybox {
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 80px 60px;
  }
  .UIElsupply .graybox h2 {
    margin-bottom: 20px;
  }
  .UIElsupply .graybox .solarimage {
    right: -60px;
    bottom: -50px;
    width: 60%;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .UIElsupply .container-fluid.background .bg-image {
    left: -30%;
    right: -30%;
  }
  .UIElsupply .container-fluid.background {
    padding: 23vw 0 18vw;
    position: relative;
  }
  .UIElsupply .overlay {
    margin-top: -1270px;
    height: 1780px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -150px;
  }
  .UIElsupply .graybox {
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 80px 60px;
  }
  .UIElsupply .graybox h2 {
    margin-bottom: 20px;
  }
  .UIElsupply .graybox .solarimage {
    right: -60px;
    bottom: -50px;
    width: 55%;
  }
}
@media (min-width: 1400px) and (max-width: 2000px) {
  .UIElsupply .container-fluid.background {
    padding: 250px 0 220px;
    position: relative;
  }
  .UIElsupply .overlay {
    margin-top: -1280px;
    height: 1780px;
  }
  .UIElsupply .container.hero-image img {
    width: 120%;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .UIElsupply .container.hero-image {
    margin-top: -250px;
  }
  .UIElsupply .hero-cards-holder {
    margin-top: -75dvh;
  }
}
@media (min-width: 2000px) {
  .UIElsupply .hero-cards-holder {
    margin-top: -80dvh;
  }
}/*# sourceMappingURL=ElSelfsuf.css.map */